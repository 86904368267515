import actions from '@/store/staking/actions';
import getters from '@/store/staking/getters';
import mutations from '@/store/staking/mutations';
import state from '@/store/staking/state';

export default {
  state,
  mutations,
  getters,
  actions,
};
