import Vue from 'vue';
import AboveForm from '@/components/AboveForm.vue';
import Alert from '@/components/Alert.vue';
import AssetHighlightIcon from '@/components/AssetHighlightIcon.vue';
import DoubleAssetHighlightIcon from '@/components/DoubleAssetHighlightIcon.vue';
import BadgeButton from '@/components/BadgeButton.vue';
import BorderButton from '@/components/BorderButton.vue';
import Checkbox from '@/components/Checkbox.vue';
import CircleButton from '@/components/CircleButton.vue';
import EllipseButton from '@/components/EllipseButton.vue';
import ComingSoon from '@/components/ComingSoon.vue';
import ConfirmationPopup from '@/components/ConfirmationPopup.vue';
import ContentForm from '@/components/ContentForm.vue';
import DirectionContentForm from '@/components/DirectionContentForm.vue';
import FCorners from '@/components/FCorners.vue';
import Dropdown from '@/components/Dropdown.vue';
import ExternalLogo from '@/components/ExternalLogo.vue';
import ExternalWidget from '@/components/ExternalWidget.vue';
import FButton from '@/components/FButton.vue';
import FInput from '@/components/FInput.vue';
import Hint from '@/components/Hint.vue';
import Icon from '@/components/Icon.vue';
import Identicon from '@/components/Identicon.vue';
import InfiniteScroll from '@/components/InfiniteScroll.vue';
import InformationBlock from '@/components/InformationBlock.vue';
import InfoRow from '@/components/InfoRow.vue';
import InputWithIcon from '@/components/InputWithIcon.vue';
import Lazy from '@/components/Lazy.vue';
import FLink from '@/components/FLink.vue';
import Loader from '@/components/Loader.vue';
import Loading from '@/components/Loading.vue';
import Logo from '@/components/Logo.vue';
import NotificationPopup from '@/components/NotificationPopup.vue';
import Popup from '@/components/Popup.vue';
import ProgressBar from '@/components/ProgressBar.vue';
import QR from '@/components/QR.vue';
import Rotate from '@/components/Rotate.vue';
import Scroll from '@/components/Scroll.vue';
import SearchInput from '@/components/SearchInput.vue';
import FSelect from '@/components/FSelect.vue';
import SelectPopup from '@/components/SelectPopup.vue';
import Shimmer from '@/components/Shimmer.vue';
import SelectInput from '@/components/SelectInput.vue';
import Slider from '@/components/Slider.vue';
import Switcher from '@/components/Switcher.vue';
import TabButton from '@/components/TabButton.vue';
import Tooltip from '@/components/Tooltip.vue';
import ValidatedInput from '@/components/ValidatedInput.vue';
import Tabs from '@/components/Tabs.vue';

//add component to component.d.ts as well
Vue.component('AboveForm', AboveForm);
Vue.component('Alert', Alert);
Vue.component('AssetHighlightIcon', AssetHighlightIcon);
Vue.component('DoubleAssetHighlightIcon', DoubleAssetHighlightIcon);
Vue.component('BadgeButton', BadgeButton);
Vue.component('BorderButton', BorderButton);
Vue.component('Checkbox', Checkbox);
Vue.component('CircleButton', CircleButton);
Vue.component('EllipseButton', EllipseButton);
Vue.component('ComingSoon', ComingSoon);
Vue.component('ConfirmationPopup', ConfirmationPopup);
Vue.component('ContentForm', ContentForm);
Vue.component('DirectionContentForm', DirectionContentForm);
Vue.component('FCorners', FCorners);
Vue.component('Dropdown', Dropdown);
Vue.component('FButton', FButton);
Vue.component('FLink', FLink);
Vue.component('FInput', FInput);
Vue.component('Hint', Hint);
Vue.component('Icon', Icon);
Vue.component('Identicon', Identicon);
Vue.component('InfiniteScroll', InfiniteScroll);
Vue.component('InformationBlock', InformationBlock);
Vue.component('InfoRow', InfoRow);
Vue.component('InputWithIcon', InputWithIcon);
Vue.component('Lazy', Lazy);
Vue.component('Loader', Loader);
Vue.component('Loading', Loading);
Vue.component('Logo', Logo);
Vue.component('ExternalLogo', ExternalLogo);
Vue.component('ExternalWidget', ExternalWidget);
Vue.component('NotificationPopup', NotificationPopup);
Vue.component('Popup', Popup);
Vue.component('ProgressBar', ProgressBar);
Vue.component('QR', QR);
Vue.component('Rotate', Rotate);
Vue.component('Scroll', Scroll);
Vue.component('SearchInput', SearchInput);
Vue.component('FSelect', FSelect);
Vue.component('SelectPopup', SelectPopup);
Vue.component('Shimmer', Shimmer);
Vue.component('SelectInput', SelectInput);
Vue.component('Slider', Slider);
Vue.component('Switcher', Switcher);
Vue.component('TabButton', TabButton);
Vue.component('Tooltip', Tooltip);
Vue.component('ValidatedInput', ValidatedInput);
Vue.component('Tabs', Tabs);
