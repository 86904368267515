import { render, staticRenderFns } from "./WarningAddressPopup.vue?vue&type=template&id=23598261"
import script from "./WarningAddressPopup.vue?vue&type=script&lang=ts&setup=true"
export * from "./WarningAddressPopup.vue?vue&type=script&lang=ts&setup=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports