import mutations from '@/store/extension/mutations';
import getters from '@/store/extension/getters';
import state from '@/store/extension/state';
import actions from '@/store/extension/actions';

export default {
  state,
  mutations,
  getters,
  actions,
};
