import { render, staticRenderFns } from "./ExternalWidget.vue?vue&type=template&id=61c50cec&scoped=true"
import script from "./ExternalWidget.vue?vue&type=script&lang=ts&setup=true"
export * from "./ExternalWidget.vue?vue&type=script&lang=ts&setup=true"
import style0 from "./ExternalWidget.vue?vue&type=style&index=0&id=61c50cec&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61c50cec",
  null
  
)

export default component.exports