import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from '@/router/routes';
import { updateTitle } from '@/util/routing';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'hash',
  routes,
});

router.beforeEach((to, from, next) => {
  // setTimeout нужен, чтобы установить нужный title после обновления страницы
  // так же, 150ms минимальное время для того, чтобы balances успели подтянуться из SW
  setTimeout(() => updateTitle(to), 150);

  next();
});

export default router;
