export enum NETWORK_STATUS {
  CONNECTED = 'connected',
  CONNECTING = 'connecting',
  DISCONNECTED = 'disconnected',
}

export enum APIItemState {
  PENDING = 'pending',
  READY = 'ready',
  ERROR = 'error',
}
