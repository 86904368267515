import { render, staticRenderFns } from "./BalanceDetailsPopup.vue?vue&type=template&id=1c08e112&scoped=true"
import script from "./BalanceDetailsPopup.vue?vue&type=script&lang=ts"
export * from "./BalanceDetailsPopup.vue?vue&type=script&lang=ts"
import style0 from "./BalanceDetailsPopup.vue?vue&type=style&index=0&id=1c08e112&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c08e112",
  null
  
)

export default component.exports