import { IS_PRODUCTION } from '@/consts/global';
import { URLS } from '@/consts/urls';
import { type EIP6963ProviderInfo } from '@/extension/entry/types';

export const ALL_ACCOUNT_KEY = 'ALL';
export const ALL_NETWORK_KEY = 'all';
export const EXTENSION_REQUEST_URL = 'extension';

const { BASE_URL } = URLS;

export const ONBOARDING_URL = IS_PRODUCTION
  ? `${BASE_URL}/master/appConfigs/onboarding/web.json`
  : `${BASE_URL}/develop-free/appConfigs/onboarding/web.json`;

export const FALLBACK_LANG = 'en-EN';
export const EXTENSION_ID = 'nhlnehondigmgckngjomcpcefcdplmgc';
export const EXTENSION_HOSTNAME = '39fb1478-3519-4b4e-8eba-15e6e594494c';

export const eip6963ProviderInfo: EIP6963ProviderInfo = {
  uuid: 'd1dc1445-2b9c-4c17-877a-7790fadfcc05',
  name: 'Fearless Wallet',
  icon: 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbDpzcGFjZT0icHJlc2VydmUiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDQwMCAxNzMiCiAgdmlld0JveD0iMCAwIDQwMCAxNzMiPgogIDxwYXRoIGZpbGw9IiNFMDciCiAgICBkPSJNMzk1LjQgMjYuMSAyNDAuNyA0Ni4zYy0uOC4xLTEuNy42LTIuNCAxLjFMMjI1LjYgNjBjLTEuNiAxLjYtNC4xIDEuNi01LjggMGwtMi4zLTIuM2MtMS42LTEuNi0xLjYtNC4yIDAtNS44bDE1LjMtMTUuMWMxLjYtMS42IDEuNi00LjIgMC01LjhsLTMwLTI5LjZjLTEuNi0xLjYtNC4xLTEuNi01LjggMGwtMzAgMjkuOGMtMS42IDEuNi0xLjYgNC4yIDAgNS44bDE1LjMgMTVjMS42IDEuNiAxLjYgNC4yIDAgNS44bC0yLjMgMi4zYy0xLjYgMS42LTQuMSAxLjYtNS44IDBsLTEyLjctMTIuNmMtLjctLjYtMS40LTEtMi40LTEuMUw0LjYgMjYuMWMtMy44LS42LTYuMiA0LjItMy40IDYuOWwzMy43IDMzLjVjMi41IDIuNS43IDYuOS0yLjggNi45LTMuNyAwLTUuNCA0LjQtMi44IDYuOWwzMS41IDMxLjRjLjYuNiAxLjEuOCAxLjggMS4xbDEwNy41IDI3Yy44LjMgMS43LjcgMi4zIDEuNGwxNi43IDIxLjJjMyA0LjEgNy45IDguOSA3LjkgOC45IDEuNiAxLjYgNC4xIDEuNiA1LjggMCAwIDAgNC4xLTQuMiA3LjktOC45bDE2LjctMjEuMmMuNi0uNyAxLjMtMS4zIDIuMy0xLjRsMTA3LjctMjdjLjctLjEgMS40LS42IDEuOC0xLjFsMzEuNS0zMS40YzIuNS0yLjUuNy02LjktMi44LTYuOS0zLjcgMC01LjQtNC40LTIuOC02LjlMMzk4LjggMzNjMi44LTIuOC40LTcuNS0zLjQtNi45eiIgLz4KICA8ZGVmcz4KICAgIDxsaW5lYXJHcmFkaWVudCBpZD0iYSIgeDE9IjQ3LjUiIHgyPSI0Ny41IiB5MT0iMS41MjgiIHkyPSI0Mi4wMTEiIGdyYWRpZW50VW5pdHM9InVzZXJTcGFjZU9uVXNlIj4KICAgICAgPHN0b3Agc3RvcC1jb2xvcj0iIzcwRSIgLz4KICAgICAgPHN0b3Agb2Zmc2V0PSIxIiBzdG9wLWNvbG9yPSIjRTA3IiAvPgogICAgPC9saW5lYXJHcmFkaWVudD4KICA8L2RlZnM+Cjwvc3ZnPg==', //assets/icons/fw-logo.svg base64
  rdns: 'io.fearlesswallet',
};
