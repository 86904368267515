import actions from '@/store/networks/actions';
import getters from '@/store/networks/getters';
import mutations from '@/store/networks/mutations';
import state from '@/store/networks/state';

export default {
  state,
  mutations,
  getters,
  actions,
};
